import * as React from "react";
import Layout from "../components/common/Layout";
import Nav from "../components/common/Nav";
import Footer from "../components/common/Footer";
import { Body, Section, Text } from "../components/common/common-styles";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";

const Card = styled.div`
  width: 200px;
  height: 200px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
`;

const PartnerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  width: 800px;
`;
// markup
const PartnersPage = () => {
  return (
    <Layout pageTitle="Partners">
      <Nav />
      <Body>
        <h2> We have partners, behold</h2>
        <PartnerList>
          <Card>
            <StaticImage src="../images/little-fork.png" alt={"little fork"} />
          </Card>{" "}
          <Card>
            <StaticImage src="../images/little-fork.png" alt={"little fork"} />
          </Card>{" "}
          <Card>
            <StaticImage src="../images/little-fork.png" alt={"little fork"} />
          </Card>{" "}
          <Card>
            <StaticImage src="../images/little-fork.png" alt={"little fork"} />
          </Card>{" "}
          <Card>
            <StaticImage src="../images/little-fork.png" alt={"little fork"} />
          </Card>{" "}
          <Card>
            <StaticImage src="../images/little-fork.png" alt={"little fork"} />
          </Card>
        </PartnerList>
      </Body>

      <Footer />
    </Layout>
  );
};

export default PartnersPage;
